<template>
  <el-dialog
    title="设置角色信息"
    width="800px"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <div class="flex justify-center">
      <div class="w-350 auth-tree mr-10">
        <div class="auth-title">
          <!-- <div class="auth-actions">
            <el-button type="text" @click="selectAll(true)" title="选中所有下级（包含当前）">全选</el-button>
            <el-button type="text" @click="selectAll(false)" title="取消所有下级（包含当前）">全不选</el-button>
          </div> -->
          全部菜单
        </div>
        <div class="auth-content" v-loading="leftLoading">
          <el-tree
            class="ml-10"
            ref="treeRef"
            node-key="permId"
            show-checkbox
            @check="nodeChecked"
            :data="permTree"
            :default-expand-all="true"
            :props="{multiple:true,value:'permId',label:'permName'}"
          ></el-tree>
        </div>
      </div>
      <div class="w-350 auth-tree ml-10">
        <div class="auth-title">已分配菜单</div>
        <div class="auth-content" v-loading="rightLoading">
          <el-tree
            class="ml-10"
            node-key="permId"
            :data="checkedList"
            :default-expand-all="true"
            :props="{value:'permId',label:'permName'}"
          />
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { listToTreeList } from '@/utils/tools'
import { getPermList } from '@/apis/authManage'
import { bindPerm2Role, getMorePerms } from '@/apis/authManage/role'
import { mapActions } from 'vuex'
import XEUtils from 'xe-utils'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: { type: Object }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    },
    checkedList () {
      const permList = JSON.parse(JSON.stringify(this.permList))
      const result = permList.filter(item => {
        return this.checkedIds.includes(item.permId)
      })
      return listToTreeList(result)
    }
  },
  data () {
    return {
      loading: false,
      checkedIds: [], // 选中的节点
      permList: [],
      permTree: [],
      sourceLoad: false, // 加载资源，当前路由下只加载一次
      leftLoading: false,
      rightLoading: false
    }
  },
  watch: {
    sourceLoad (newValue) {
      if (newValue) {
        this.leftLoading = true
        getPermList({
          pageNum: 1,
          pageSize: 10000
        }).then((res) => {
          this.leftLoading = false
          if (res.code == 200) {
            this.permList = res.data.rows
            const permList = JSON.parse(JSON.stringify(this.permList))
            this.permTree = listToTreeList(permList)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  },
  methods: {
    ...mapActions('account', ['refreshToken']),
    selectChilden (data, checked) {
      XEUtils.eachTree([data], (item) => {
        this.$refs.treeRef.setChecked(item, checked)
      })
      this.nodeChecked()
    },
    selectAll (checked) {
      XEUtils.eachTree(this.permTree, (item) => {
        this.$refs.treeRef.setChecked(item, checked)
      })
      this.nodeChecked()
    },
    nodeChecked () {
      const checkedIds = this.$refs.treeRef.getCheckedKeys() // 全选节点
      const checkedHalfIds = this.$refs.treeRef.getHalfCheckedKeys() // 全选节点
      this.checkedIds = [].concat(checkedIds, checkedHalfIds)
    },

    setCheckedKeys (permIds) {
      this.$refs.treeRef.setCheckedKeys(permIds)
      setTimeout(() => {
        this.nodeChecked()
      }, 200)
    },

    submit () {
      const permParams = {
        ids: this.checkedIds,
        id: this.editData.roleId
      }
      // 绑定权限
      this.loading = true
      bindPerm2Role(permParams).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.$message.success('权限配置成功！')
          this.$emit('refresh')
          this.visibleDialog = false
          // 刷新token和用户信息
          this.refreshToken()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 初始化表单
    async handlerOpen () {
      if (!this.sourceLoad) this.sourceLoad = true

      this.rightLoading = true
      const res = await getMorePerms({
        id: this.editData?.roleId
      })
      this.rightLoading = false
      if (Number(res.code) === 200) {
        const role = (res.data?.rows || [])[0]
        let perms = (role?.perms || [])
        perms = perms.filter(item => {
          const notLeaf = perms.some(subItem => item.permId == subItem.permPId)
          return !notLeaf
        })
        const permIds = perms.map(item => item.permId)
        this.$nextTick(() => {
          this.setCheckedKeys(permIds)
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    handlerClose () {
      this.$emit('update:edit-data', null)
      this.setCheckedKeys([])
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    .custom-node-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &:hover .custom-action-btn {
        display: block;
    }
    .custom-action-btn {
        display: none;
    }
}
.auth-tree {
  height: 450px;
  border: 1px solid #ebeef5;
  border-radius: 3px;
    .auth-title {
        font-weight: bold;
        position: relative;
        background: #f5f7fa;
        text-align: center;
        line-height: 32px;
    }
    .auth-actions {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);

        ::v-deep .el-button {
            margin-left: 3px;
        }
    }

  .auth-content {
    overflow-y: auto;
    height: 418px;
    padding: 5px 0;
  }
}
</style>

import http from '@/utils/http'

export function addRole (p) {
  return http.post('/mortconc/rolecs/set', p)
}

export function getRoleList (p) {
  return http.post('/mortconc/rolecs/list', p)
}

export function delRole (p) {
  return http.post('/mortconc/rolecs/del', p)
}

export function bindPerm2Role (p) {
  return http.post('/mortconc/rolecs/boundPerm', p)
}

// 获取某个角色下的菜单列表
export function getMorePerms (p) {
  return http.post('/mortconc/rolecs/getMorePerms', p)
}

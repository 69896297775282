<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="角色名称:">
        <el-input v-model.trim="searchForm.roleName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="角色码:">
        <el-input v-model.trim="searchForm.roleCode" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="角色类型:">
        <DataSelect ref="dataSelect" v-model="searchForm.roleType" dictCode="data_role_type"></DataSelect>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="role"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData" >
      <template #buttons>
        <el-button size="small" v-auth="'权限管理_角色管理_新增'" type="primary" icon="el-icon-plus" @click="addVisible=true">新 增</el-button>
      </template>
      <vxe-column type="seq" title="序号" align="center" width="80"></vxe-column>
      <vxe-column field="roleCode" title="角色码"></vxe-column>
      <vxe-column field="roleName" title="角色名称"></vxe-column>
      <vxe-column field="roleTypeName" title="角色类型"></vxe-column>
      <vxe-column field="remark" title="备注" show-overflow="title"></vxe-column>
      <vxe-column field="regtime" title="创建时间" show-overflow="title"></vxe-column>
      <vxe-column field="sysName" title="系统名称"></vxe-column>
      <vxe-column title="系统图标" width="120">
        <template #default={row}>
          <div v-if="row.sysPicURL" class="flex align-center justify-center w-24 h-24" :style="{background:$store.state.settings.theme}">
            <img class="system-icon-item" :src="getMediaUrl(row.sysPicURL)">
          </div>
        </template>
      </vxe-column>
      <vxe-column field="statusName" title="使用状态"></vxe-column>
      <vxe-column title="操作" width="220">
        <template #default={row}>
          <el-button v-auth="'权限管理_角色管理_编辑'" type="primary" @click="goEditAccount(row)" title="编辑账号">编辑</el-button>
          <el-button type="primary" @click="goEditRole(row)" title="配置菜单">配置菜单</el-button>
          <el-button v-auth="'权限管理_角色管理_删除'" type="danger" @click="deleteRole(row.roleId)" title="删除账号">删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 编辑角色 -->
    <edit-role :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList" />
    <!-- 新增角色 -->
    <add-role :visible.sync="addVisible" :edit-data.sync="editData" @refresh="initList" />
  </PageContainer>
</template>

<script>
import { getRoleList, delRole } from '@/apis/authManage/role'
import EditRole from './EditRole'
import AddRole from './AddRole'
import { getDictionary } from '@/apis/authManage/dataDictionary'

export default {
  data () {
    return {
      roleTypes: {
        4: '政府',
        5: '小程序',
        7: '最高权限',
        1: '企业',
        6: '供应商',
        3: '协会',
        2: '集团'
      },
      searchForm: {
        roleName: '', // 角色名称
        roleCode: '', // 角色码
        roleType: '' // 角色类型
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 50,
        totalSize: 0
      },
      // 编辑相关
      editData: null,
      editVisible: false,
      addVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { tablePage } = this
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getRoleList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          getDictionary({ dictCode: 'data_role_type' }).then(({ data }) => {
            this.tableData = res.data.rows.map((item) => {
              const target = data?.find((dict) => item.roleType == dict.dictValue) || { dictName: '' }
              item.roleTypeName = target?.dictName
              item.statusName = item.status == 0 ? '禁用' : '启用'
              return item
            })
            tablePage.totalSize = res.data.totalSize
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    goEditRole (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    goEditAccount (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.addVisible = true
    },

    deleteRole (id) {
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = { ids: [id] }
        delRole(params).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  components: {
    EditRole,
    AddRole
  }
}
</script>

<style lang="scss" scoped>
.system-icon-item {
  object-fit: contain;
  width: 24px;
  max-height: 24px;
}
</style>

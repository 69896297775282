<template>
  <el-dialog
    v-bind="{title:submitForm.roleId?'编辑角色':'新建角色',width:'430px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form ref="form" label-width="105px" :model="submitForm" :rules="rules">
      <el-form-item label="角色类型" prop="roleType">
        <DataSelect class="w-250" v-model="submitForm.roleType" dictCode="data_role_type"></DataSelect>
      </el-form-item>
      <el-form-item label="角色名称" prop="roleName">
        <el-input class="w-250" v-model.trim="submitForm.roleName" clearable></el-input>
      </el-form-item>
      <el-form-item label="角色码" prop="roleCode">
        <el-input class="w-250" v-model.trim="submitForm.roleCode" placeholder="请输入角色码，以ROLE_开头" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input class="w-250" v-model.trim="submitForm.remark" clearable></el-input>
      </el-form-item>
      <el-form-item label="系统名称">
        <el-input class="w-250" v-model.trim="submitForm.sysName" clearable></el-input>
      </el-form-item>
      <el-form-item label="系统图标">
        <upload-pic
          :src="submitForm.sysPicURL"
          @uploaded="submitForm.sysPicURL=$event.nowRelatUrl"
          @remove="submitForm.sysPicURL=''"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import UploadPic from '@/components/UploadPic'
import { addRole } from '@/apis/authManage/role'
import { mapActions } from 'vuex'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      submitForm: {
        roleId: '', // 角色id
        roleType: '', // 角色类型
        roleName: '', // 角色名称
        roleCode: '', // 角色码
        remark: '', // 备注
        sysName: '', // 角色系统名称
        sysPicURL: '' // 角色系统图标
      },
      rules: {
        roleType: { required: true, trigger: 'change', message: '请选择角色类型' },
        roleName: { required: true, trigger: 'change', message: '请输入角色名称' }
      },
      loading: false
    }
  },
  methods: {
    ...mapActions('account', ['refreshToken']),

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          addRole(this.submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('创建成功！')
              this.$emit('refresh')
              this.visibleDialog = false
              // 刷新token和用户信息
              this.refreshToken()
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    },

    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    // 初始化表单
    handlerOpen () {
      const { submitForm, editData } = this
      if (!editData) return

      Object.keys(submitForm).forEach((key) => {
        const value = editData[key]
        submitForm[key] = value
      })
    }

  },
  components: {
    UploadPic
  }
}
</script>
